const preguntas = [
	{
		pregunta: "Elegí la cosa que mas ames",
		opciones: [
			"Juegos de mesa",
			"Python",
			"Odiar Python",
			"Boca Juniors",
			"C#",
			"C++",
			"Odiar Desarrollo Web",
			"UX design",
			"Solidity",
			"Emojis > Texto",
			"Unity",
			"No saber y odiar Java Script",
			"Trabajar casos reales",
			"Todo lo del diseño",
			"Assembler",
			"Rhino",
			"Amar diseño web",
		],
	},
	{
		pregunta: "Elegí una segunda característica / una opinión controversial",
		opciones: [
			"Amar las papas",
			"Vestirse de invierno SIEMPRE",
			"Lagrima mata café",
			"Medialunas de grasa > Medialunas de manteca",
			"Tomar la chocolatada sin azúcar",
			"Mate mata todo",
			"Tomar la chocolatada con azúcar",
			"Amar el Café instantáneo",
			"Mate cocido mata todo",
			"Amar el locro",
			"Odiar el te",
			"Cafe mata todo",
			"Coca cola mata toda bebida",
			"Tomar el café mitad mitad (mitad leche, mitad café)",
			"Pensar que la pizza es la mejor comida",
			"Café de Especialidad > cualquier otro tipo de café",
			"Pensar que el café negro mata lagrima",
		],
	},
	{
		pregunta: "Elegí una tercera característica / una opinión controversial",
		opciones: [
			"Tener un gusto de musica variado",
			"Usar IOS/Apple (Muerte a Windows)",
			"Usar Windows (muerte a ios)",
			"Ser Indeciso",
			"Ser Team invierno",
			"Amar la animacion 3d",
			"Opinar que las peliculas mata series",
			"No creer en la astrologia",
			"Opinar que las series mata peliculas",
			"Amar Tiktok (ser tiktoker)",
			"Opinar que jazz mata cualquier genero",
			"Amar Pixelart",
			"Odiar la playa",
			"Amar Starbucks",
			"Ser sustentable",
			"Amar los lentes de sol",
			"Amar los recitales",
		],
	},
	{
		pregunta: "Elegí una ultima  característica / una opinión controversial",
		opciones: [
			"Amar los perros y gatos",
			"Bañarse a la mañana",
			"Ser familiero",
			"Opinar que Back > front",
			"Opinar que front > back",
			"Ser impuntual",
			"Dar pruebas de programcion",
			"Opinar que montaña mata playa",
			"Ser puntual",
			"Decir que Tic = Familia",
			"Bañarse a la noche",
			"Amar el juego celeste",
			"Usar mucho buzo y gorrito",
			"Team harry potter",
			"Disney > todo",
			"Accesibilizar todo",
			"Team verano",
		],
	},
];

const profes = {
	Caro: [
		"Juegos de mesa",
		"Amar las papas",
		"Tener un gusto de musica variado",
		"Amar los perros y gatos",
	],
	Chona: [
		"Python",
		"Vestirse de invierno SIEMPRE",
		"Usar IOS/Apple (Muerte a Windows)",
		"Bañarse a la mañana",
	],
	Daro: ["Solidity", "Lagrima mata café", "Usar Windows (muerte a ios)", "Ser familiero"],
	Nacho: [
		"Odiar Python",
		"Medialunas de grasa > Medialunas de manteca",
		"Ser Indeciso",
		"Opinar que Back > front",
	],
	Ivo: [
		"Boca Juniors",
		"Tomar la chocolatada sin azúcar",
		"Ser Team invierno",
		"Opinar que front > back",
	],
	Jero: ["C#", "Mate mata todo", "Amar la animacion 3d", "Ser impuntual"],
	Joaco: [
		"C++",
		"Tomar la chocolatada con azúcar",
		"Opinar que las peliculas mata series",
		"Dar pruebas de programcion",
	],
	"Juli S": [
		"No saber y odiar Java Script",
		"Pensar que el café negro mata lagrima",
		"No creer en la astrologia",
		"Opinar que montaña mata playa",
	],
	"Juli A": [
		"Trabajar casos reales",
		"Amar el Café instantáneo",
		"Opinar que las series mata peliculas",
		"Ser puntual",
	],
	Lu: [
		"Emojis > Texto",
		"Mate cocido mata todo",
		"Amar Tiktok (ser tiktoker)",
		"Decir que Tic = Familia",
	],
	Aro: [
		"Amar diseño web",
		"Amar el locro",
		"Opinar que jazz mata cualquier genero",
		"Bañarse a la noche",
	],
	Mati: ["Unity", "Odiar el te", "Amar Pixelart", "Amar el juego celeste"],
	Luca: ["Odiar Desarrollo Web", "Cafe mata todo", "Usar mucho buzo y gorrito", "Odiar la playa"],
	Mica: ["Assembler", "Coca cola mata toda bebida", "Amar Starbucks", "Team harry potter"],
	Pau: [
		"Rhino",
		"Tomar el café mitad mitad (mitad leche, mitad café)",
		"Ser sustentable",
		"Disney > todo",
	],
	Ranzo: [
		"UX design",
		"Pensar que la pizza es la mejor comida",
		"Amar los lentes de sol",
		"Accesibilizar todo",
	],
	Sofi: [
		"Todo lo del diseño",
		"Café de Especialidad > cualquier otro tipo de café",
		"Amar los recitales",
		"Team verano",
	],
};

export { preguntas, profes };

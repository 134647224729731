/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import "./App.css";
import { preguntas, profes } from "./preguntas.js";
import casas from "./casas.webp";

function App() {
	const [selected, setSelected] = useState(-1);
	const [page, setPage] = useState(1);
	const [answers, setAnswers] = useState({
		pregunta1: -1,
		pregunta2: -1,
		pregunta3: -1,
		pregunta4: -1,
	});
	const [result, setResult] = useState();
	const pages = 4;

	const handleClick = (index) => {
		setAnswers({
			...answers,
			[`pregunta${page}`]: index,
		});
		setSelected(index);
	};

	const finish = () => {
		setResult(calculateResult());
	};

	const calculateResult = () => {
		let resultados = {};
		let ganador = "";
		Object.values(answers).forEach((key, i) => {
			Object.keys(profes).forEach((profe) => {
				if (profes[profe].includes(preguntas[i]["opciones"][key])) {
					if (i === 0) {
						resultados[profe] = 1.1;
					} else {
						resultados[profe] ? (resultados[profe] += 1) : (resultados[profe] = 1);
					}
				}
			});
		});
		Object.keys(resultados).forEach((key) => {
			if (resultados[key] > ganador) {
				ganador = key;
			}
		});
		return ganador;
	};

	const prevPage = () => {
		if (page === 1) return;
		setPage(page - 1);
	};

	const nextPage = () => {
		if (page === pages) return;
		setPage(page + 1);
	};

	useEffect(() => {
		setSelected(answers[`pregunta${page}`]);
	}, [page]);

	return (
		<>
			<div className="card">
				{result ? (
					<>
						<div className="result">
							<span>Sos de la casa de {result}</span>
							<img src={casas} alt="casas" />
						</div>
					</>
				) : (
					<>
						<div className="card-header">
							<button onClick={prevPage} disabled={page === 1}>
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="currentColor">
									<path d="M12.586 27.414l-10-10c-0.781-0.781-0.781-2.047 0-2.828l10-10c0.781-0.781 2.047-0.781 2.828 0s0.781 2.047 0 2.828l-6.586 6.586h19.172c1.105 0 2 0.895 2 2s-0.895 2-2 2h-19.172l6.586 6.586c0.39 0.39 0.586 0.902 0.586 1.414s-0.195 1.024-0.586 1.414c-0.781 0.781-2.047 0.781-2.828 0z"></path>
								</svg>
							</button>
							<div className="page">{page} / 4</div>
							<button onClick={nextPage} disabled={answers[`pregunta${page}`] === -1}>
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="currentColor">
									<path d="M19.414 27.414l10-10c0.781-0.781 0.781-2.047 0-2.828l-10-10c-0.781-0.781-2.047-0.781-2.828 0s-0.781 2.047 0 2.828l6.586 6.586h-19.172c-1.105 0-2 0.895-2 2s0.895 2 2 2h19.172l-6.586 6.586c-0.39 0.39-0.586 0.902-0.586 1.414s0.195 1.024 0.586 1.414c0.781 0.781 2.047 0.781 2.828 0z"></path>
								</svg>
							</button>
						</div>
						<div className="card-body">
							<h2 className="card-title">{preguntas[page - 1].pregunta}</h2>

							<div className="card-choices">
								{preguntas[page - 1].opciones.map((item, i) => (
									<div
										className={`card-choice ${i === selected ? "selected" : ""}`}
										key={i}
										onClick={() => handleClick(i)}
									>
										<span>{item}</span>
									</div>
								))}
							</div>
						</div>
						<div className="card-buttons">
							{page !== 4 ? (
								<button onClick={nextPage} disabled={answers[`pregunta${page}`] === -1}>
									Siguiente
								</button>
							) : (
								<button onClick={finish} disabled={answers[`pregunta${page}`] === -1}>
									Terminar
								</button>
							)}
						</div>
					</>
				)}
			</div>
		</>
	);
}

export default App;
